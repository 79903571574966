import React, { useEffect, useMemo, useState } from "react";
import { Props } from "react-select";
import Select from "react-select";
import { observer } from "mobx-react-lite";
import useRootStore from "../../../hooks/rootStoreHook";
import { Country } from "../../../models/Country";
import customSelect from "../../../styles/custom-select";
import { Unwrap } from "../../../utils/api/apiClient";

export default observer(function CountrySelector({
  selectProps,
  onSelected,
  value,
  regionIds,
  regionsData,
  isRegionManualChanged,
}: {
  selectProps?: Props;
  value: any;
  onSelected: (newValue: unknown) => void;
  regionIds?: string[];
  regionsData?: any[];
  isRegionManualChanged?: boolean;
}) {
  const { apiClient } = useRootStore();
  const [countries, setCountries] = useState<Country[] | null>([]);

  useEffect(() => {
    const getCountriesByRegionIds = async () => {
      const regions = regionIds?.map((r) => `regionIds=${r}`).join("&");
      const usId = regionsData?.find((r) => r.region === "US States")?.id;

      try {
        const response = await apiClient.get(`Countries/regions?${regions}`);
        const result = Unwrap.result(response);
        setCountries(result);

        if (isRegionManualChanged) {
          if (!regionIds?.includes(usId)) {
            onSelected(result?.map((r) => ({ value: r.id, label: r.name })));
            setCountries(result);
            return;
          }
          setCountries(result);
        }
      } catch (err) {
        setCountries([]);
      }
    };

    if (regionIds?.length) {
      getCountriesByRegionIds();
    } else {
      setCountries([]);
    }
  }, [regionIds, isRegionManualChanged]);

  const getValues = () => {
    return value
      ?.map((id) => {
        const foundCountry = countries?.find((country) => country.id === id);
        return foundCountry ? { value: id, label: foundCountry.name } : null;
      })
      .filter(Boolean);
  };

  return (
    <Select
      styles={customSelect("primary")}
      autoFocus={true}
      value={getValues()}
      placeholder={countries === null ? "Loading" : "Select countries..."}
      onChange={onSelected}
      isMulti
      options={countries?.map((a) => {
        return { value: a.id!, label: a.name! };
      })}
      isDisabled={countries === null || !regionIds?.length}
      {...selectProps}
    />
  );
});
