import React from "react";
import Wrapper from "../../Wrapper";
import MarkingForm from "./MarkingForm";

const CreateMarking = () => {
  return (
    <Wrapper headline="Create Global marking">
      <MarkingForm />
    </Wrapper>
  );
};

export default CreateMarking;
