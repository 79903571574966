import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import useRootStore from "../../../hooks/rootStoreHook";
import { Unwrap } from "../../../utils/api/apiClient";
import Wrapper from "../../Wrapper";
import { Card, Form, Button, Container, Spinner } from "react-bootstrap";
import { Product } from "../../../models/Product";
import ProductSelector from "../../Products/ProductSelector";
import Select from "react-select";
import { Country } from "../../../models/Country";
import customSelect from "../../../styles/custom-select";

const GlobalStandardFinder: React.FC<{ product?: Product; onLoaded }> = (
  props
) => {
  const { apiClient } = useRootStore();
  const [productComplianceDocuments, setProductComplianceDocuments] = useState<
    Product["complianceDocuments"]
  >([]);
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [countries, setCountries] = useState<Country[]>([]);
  const [resultText, setResultText] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const handle = async () => {
      const res = await apiClient.get("/countries");
      const result = Unwrap.result(res);
      setCountries(result);
    };

    handle();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (productComplianceDocuments && selectedCountry) {
      setIsLoading(true);
      const documentNames = productComplianceDocuments
        .map((document) => document.name)
        .join(", ");
      const text = encodeURIComponent(
        `what is the equivalent of ${documentNames} in ${selectedCountry}`
      );

      const res = await apiClient.post(
        `/tools/GlobalStandardFinder/AskQuestion/?text=${text}`
      );
      setResultText(res.data || "No results found.");
      setIsLoading(false);
    }
  };

  return (
    <Wrapper headline="Global Standard Finder">
      <div>
        <div>
          <Form onSubmit={handleSubmit}>
            <div>
              <div className="row align-items-center mx-0 mb-4 bg-secondary rounded-xl p-3">
                <div className="flex-grow-1 px-0">
                  <div>
                    {!props.product?.id ? (
                      <ProductSelector
                        selectOnChange
                        onSelected={(val) => {
                          setProductComplianceDocuments(
                            (val as Product)?.complianceDocuments
                          );
                        }}
                      />
                    ) : (
                      <Select
                        styles={customSelect()}
                        isDisabled
                        placeholder={props.product.name}
                      />
                    )}
                  </div>
                </div>
                <div className="flex-grow-1 px-0 ml-3">
                  <Select
                    styles={customSelect("primary")}
                    options={countries.map((a) => ({
                      value: a.name!,
                      label: a.name!,
                    }))}
                    onChange={(e) => setSelectedCountry(e?.value)}
                  />
                </div>

                <div className="ml-3 d-flex justify-content-center">
                  <Button
                    type="submit"
                    disabled={!selectedCountry || !productComplianceDocuments}
                    variant="success"
                  >
                    Search
                  </Button>
                </div>
              </div>

              <div className="b border-0">
                <div className="col-12">
                  <Form.Group controlId="formProductNotes">
                    <Form.Label className="text-foreground text-xl mb-3 d-block">
                      Result
                    </Form.Label>
                    {isLoading ? (
                      <div className="d-flex justify-content-center align-items-center my-2 text-lg">
                        <Spinner animation="grow" variant="primary" />{" "}
                        Loading...
                      </div>
                    ) : resultText === "" ? (
                      <div className="bg-secondary p-5 h-100 column text-center rounded-lg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          viewBox="0 0 24 24"
                          fill="none"
                          className="bg-white rounded-lg self-center p-2 shadow-sm border"
                        >
                          <path
                            d="M21 21L17.5001 17.5M20 11.5C20 16.1944 16.1944 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5Z"
                            stroke="#344054"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <h3 className="text-foreground p-5 m-2">
                          Results will be shown here
                        </h3>
                      </div>
                    ) : (
                      <Form.Control
                        as="textarea"
                        rows={20}
                        disabled
                        value={resultText}
                        onChange={(e) => setResultText(e.target.value)}
                      />
                    )}
                  </Form.Group>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default observer(GlobalStandardFinder);
