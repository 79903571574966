import React, { useState, useEffect } from "react";
import { Product } from "../../models/Product";
import { Unwrap } from "../../utils/api/apiClient";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import QueryFilterBuilder from "../../utils/queryFilterBuilder";
import useRootStore from "../../hooks/rootStoreHook";
import { Option } from "react-bootstrap-typeahead/types/types";
export interface SelectedProduct {
  name: string;
  id: string;
}

const ProductSelector: React.FC<{
  selectOnChange?: boolean;
  onSelected: (value: SelectedProduct | SelectedProduct[] | undefined) => void;
  products?: Product[];
  multi?: boolean;
}> = function (props) {
  const { apiClient } = useRootStore();
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(
    []
  );

  function emitSelected() {
    if (props.multi === true) props.onSelected(selectedProducts);
    else props.onSelected(selectedProducts[0]);
  }

  useEffect(() => {
    if (props.selectOnChange !== true) return;
    emitSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectOnChange, selectedProducts]);

  useEffect(() => {
    if (!!props.products) setProducts(props.products);
    else {
      apiClient.get(`products?page=1&pageSize=10&startWith=gu`).then((res) => {
        let result = Unwrap.result(res);
        setProducts(result.pageResults);
      });
    }
  }, [apiClient, props.products]);

  const searchProduct = async (input: string) => {
    let searchStr = input || "";

    var filterBuilder = QueryFilterBuilder.Create();
    let str = filterBuilder
      .Property("productinfo")
      .Contains(searchStr)
      .NotCaseSensitive()
      .Build();
    await apiClient
      .get(`products?page=1&pageSize=10&filters=${str}`)
      .then((res) => {
        let result = Unwrap.result(res);
        setProducts(result.pageResults);
      });
  };

  // const selectProduct = async (selectedProducts: SelectedProduct[]) => {
  //   if (Array.isArray(selectedProducts)) {
  //     if (props.multi === true)
  //       setSelectedProducts(
  //         selectedProducts.map((a) => {
  //           return { name: a.name, id: a.id };
  //         })
  //       );
  //     else {
  //       let prod = selectedProducts[0];
  //       if (!prod) setSelectedProducts([]);
  //       else
  //         setSelectedProducts([
  //           { id: selectedProducts[0].id, name: selectedProducts[0].name },
  //         ]);
  //     }
  //   } else
  //     throw new Error(
  //       "Selected products is not an array: " + JSON.stringify(selectedProducts)
  //     );
  // };

  return (
    <React.Fragment>
      <Form.Group>
        <Typeahead
          id="basic-typeahead-single"
          multiple={props.multi ?? false}
          labelKey="name"
          onInputChange={(input, e) => searchProduct(input)}
          options={products}
          placeholder="Select product..."
          onChange={(e: any) => {
            setSelectedProducts(e);
          }}
          selected={selectedProducts}
          filterBy={() => true} //Note: The backend filters for us, on multiple aspects. Frontend should just list results
        />
      </Form.Group>
    </React.Fragment>
  );
};
export default ProductSelector;
