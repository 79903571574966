import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import customSelect from "../../../styles/custom-select";

const Requirements = ({ requirements, onRequirementChange }) => {
  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const updatedVersions = [...requirements];
    updatedVersions[index][name] = value;
    onRequirementChange(updatedVersions);
  };

  const addVersion = () => {
    onRequirementChange([
      ...requirements,
      {
        headline: "",
        text: "",
        order: requirements.length + 1,
      },
    ]);
  };

  const removeVersion = (index) => {
    const updatedVersions = requirements.filter((_, i) => i !== index);
    onRequirementChange(updatedVersions);
  };

  return (
    <div className="my-4">
      <h3>Requirements</h3>
      {requirements?.map((req, index) => (
        <Form key={index} className="mb-4">
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="headline"
                  value={req.headline}
                  onChange={(event) => handleChange(index, event)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Description"
                  name="text"
                  value={req.text}
                  onChange={(event) => handleChange(index, event)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Order</Form.Label>
                <Select
                  name="order"
                  value={{ label: String(req.order), value: req.order }}
                  options={Array.from({ length: 25 }, (_, i) => ({
                    label: (i + 1).toString(),
                    value: i + 1,
                  }))}
                  placeholder="select order"
                  styles={customSelect("primary")}
                  onChange={(event) =>
                    handleChange(index, {
                      target: { name: "order", value: event?.value },
                    })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Col md={6} className="d-flex align-items-end mt-4">
            <Button variant="danger" onClick={() => removeVersion(index)}>
              Remove
            </Button>
          </Col>
        </Form>
      ))}

      <Button
        variant="primary"
        onClick={addVersion}
        className="ml-auto d-block"
      >
        + Add Requirement
      </Button>
    </div>
  );
};

export default Requirements;
