import React from "react"
import { format } from 'date-fns'

export const defaultDateFormatString = "yyyy-MM-dd";

export const DateFormatter = function(dateString: string | Date | undefined | null, dateFormat? : string | null) {
    if (!dateString) return null;

    const formatStr = !!dateFormat ? dateFormat : defaultDateFormatString;
    if (typeof dateString === "string") return format(new Date(dateString), formatStr);
    if (typeof dateString.getMonth === "function") return <>{format(dateString as Date, formatStr)}</>;
    throw Error("Invalid datestring");
}

export const DateFormat = ( { dateString, dateFormat, emptyReplacement }) => {
    if (!dateString) return <>{emptyReplacement ?? null}</>;

    return DateFormatter(dateString, dateFormat);
}

export default DateFormat;