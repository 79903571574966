import React, { useEffect, useState } from "react";
import { Props } from "react-select";
import Select from "react-select";
import { observer } from "mobx-react-lite";
import useRootStore from "../../../hooks/rootStoreHook";
import customSelect from "../../../styles/custom-select";
import { Unwrap } from "../../../utils/api/apiClient";

export default observer(function RegionSelector({
  selectProps,
  onSelected,
  value,
  setRegionsData,
  setIsRegionManualChanged,
}: {
  selectProps?: Props;
  value?: any;
  onSelected: (val: string | string[]) => void;
  setRegionsData?: React.Dispatch<React.SetStateAction<never[]>>;
  setIsRegionManualChanged?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { apiClient } = useRootStore();
  const [regions, setRegions] = useState<
    { id: string; region: string }[] | null
  >([]);

  useEffect(() => {
    const getRegions = async () => {
      try {
        const response = await apiClient.get("/Regions");
        const result = Unwrap.result(response);
        setRegions(result);
        if (setRegionsData) {
          setRegionsData(result);
        }
      } catch (err) {
        setRegions([]);
        if (setRegionsData) {
          setRegionsData([]);
        }
      }
    };

    getRegions();
  }, []);

  function setSelectedRegions(val: any) {
    onSelected(val);
  }

  const getValues = () => {
    return value
      ?.map((id) => {
        const foundRegion = regions?.find((region) => region.id === id);
        return foundRegion ? { value: id, label: foundRegion.region } : null;
      })
      .filter(Boolean);
  };

  const isUSStatesSelected = value?.some((id) => {
    const selectedRegion = regions?.find((region) => region.id === id);
    return selectedRegion?.region === "US States";
  });

  const isOtherRegionSelected = value?.some((id) => {
    const selectedRegion = regions?.find((region) => region.id === id);
    return selectedRegion?.region !== "US States";
  });

  const customStyles = {
    ...customSelect("primary"),
    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "#ccc" : provided.color,
      backgroundColor: state.isDisabled ? "#f0f0f0" : provided.backgroundColor,
      cursor: state.isDisabled ? "not-allowed" : "default",
    }),
  };

  return (
    <Select
      styles={customStyles}
      autoFocus={true}
      value={getValues()}
      placeholder={regions === null ? "Loading" : "Select regions..."}
      onChange={(val: any) => {
        setSelectedRegions(val);
        if (setIsRegionManualChanged) setIsRegionManualChanged(true);
      }}
      isMulti
      options={regions!.map((a) => {
        return { value: a.id!, label: a.region! };
      })}
      isDisabled={regions === null}
      isOptionDisabled={(option: any) =>
        (isUSStatesSelected && option.label !== "US States") ||
        (isOtherRegionSelected && option.label === "US States")
      }
      {...selectProps}
    />
  );
});
