import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import customSelect from "../../../styles/custom-select";
import DateTimePicker from "react-datetime-picker";
import useRootStore from "../../../hooks/rootStoreHook";
import { defaultDateFormatString } from "../../Helper_component/DateFormat";
import RegionSelector from "./RegionSelector";
import { dangerConfirmation, toast } from "../../Helper_component/Swal";
import AsyncSelect from "react-select/async";
import { mockFetch } from "../../../utils/api/mockFetch";
import { Unwrap } from "../../../utils/api/apiClient";
import Chips from "react-chips";
import CountrySelector from "./CountrySelector";
import { CalendarMiniIcon, ClearIcon } from "../../../assets/icons";
import Requirements from "./Requirements";

interface Country {
  value: string;
  name: string;
}

interface Metadata {
  title: string;
  value: string;
}

interface FormData {
  id?: string;
  name: string;
  description: string;
  enforcementType: "Unknown";
  steps: string;
  manuals: string;
  packaging: string;
  countries: string[];
  regions: string[];
  requirements: any;
  adherenceRequirements: {
    headline: string;
    text: string;
    order: number | null;
  }[];
  sources: string[];
  certificationValidityPeriod: string;
  certificationOrganization: string;
  packagingRequirements: string;
  manualsRequirements: string;
  metadata: Metadata[];
  validFrom: Date;
  validTo: Date;
  image: string;
}

const MarkingForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [regionsData, setRegionsData] = useState([]);
  const [isRegionManualChanged, setIsRegionManualChanged] = useState(false);
  const [form, setForm] = useState<FormData>({
    name: "Test Global Marking",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    enforcementType: "Unknown",
    steps: "string",
    manuals: "string",
    packaging: "string",
    certificationValidityPeriod: "string",
    certificationOrganization: "string",
    packagingRequirements: "string",
    manualsRequirements: "string",
    validFrom: new Date(),
    validTo: new Date(),
    requirements: [],
    adherenceRequirements: [
      {
        headline: "",
        text: "",
        order: 1,
      },
    ],
    sources: [],
    countries: [],
    regions: [],
    metadata: [],
    image: "",
  });

  const { sessionStore, apiClient } = useRootStore();
  const currentSpace = sessionStore.currentSpace;
  const dateTimeFormat =
    currentSpace?.adminSetting?.dateTimeFormat ?? defaultDateFormatString;

  const onSubmit = async (e) => {
    e.preventDefault();
    const res = await dangerConfirmation.fire(
      "Are you sure you want to create this?"
    );
    if (!res.value) return;

    const payload = {
      ...form,
      countries: form.countries?.map((c: any) => c.value),
      regions: form.regions?.map((r: any) => r.value),
    };

    setIsLoading(true);
    try {
      await apiClient.post("tools/GlobalMarkings", payload);
      setIsLoading(false);
      toast.fire("Successfully Created!");
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const regionValues = useMemo(() => {
    return form.regions.map((val: any) => val.value);
  }, [form.regions]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleRequirementsChange = (val) => {
    setForm((prev) => ({ ...prev, adherenceRequirements: val }));
  };

  const handleSelectChange = (name, val) => {
    setForm((prevData) => ({ ...prevData, [name]: val }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      setForm((prev) => ({
        ...prev,
        image: reader.result as string,
      }));
    };
    reader.readAsDataURL(file);
  };

  const removeFile = () => {
    setForm((prev) => ({ ...prev, image: "" }));
  };

  return (
    <div>
      <Form onSubmit={onSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group className="valid-group mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder="Name"
                name="name"
                onChange={handleChange}
                value={form.name}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="valid-group mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                placeholder="Description"
                name="description"
                onChange={handleChange}
                value={form.description}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="valid-group mb-3">
              <Form.Label>Enforcement Type</Form.Label>
              <Form.Control
                placeholder="Enforcement type"
                name="enforcementType"
                onChange={handleChange}
                value={form.enforcementType}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="valid-group mb-3">
              <Form.Label>Steps</Form.Label>
              <Form.Control
                placeholder="Steps"
                name="steps"
                onChange={handleChange}
                value={form.steps}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="valid-group mb-3">
              <Form.Label>Manuals</Form.Label>
              <Form.Control
                placeholder="Manuals"
                name="manuals"
                onChange={handleChange}
                value={form.manuals}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="valid-group mb-3">
              <Form.Label>Packaging</Form.Label>
              <Form.Control
                placeholder="Packaging"
                name="packaging"
                onChange={handleChange}
                value={form.packaging}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="valid-group mb-3">
              <Form.Label>Certification Validity Period</Form.Label>
              <Form.Control
                placeholder="Certification Validity Period"
                name="certificationValidityPeriod"
                onChange={handleChange}
                value={form.certificationValidityPeriod}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="valid-group mb-3">
              <Form.Label>Certification Organization</Form.Label>
              <Form.Control
                placeholder="Certification Organization"
                name="certificationOrganization"
                onChange={handleChange}
                value={form.certificationOrganization}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="valid-group mb-3">
              <Form.Label>Packaging Requirements</Form.Label>
              <Form.Control
                placeholder="Packaging Requirements"
                name="packagingRequirements"
                onChange={handleChange}
                value={form.packagingRequirements}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="valid-group mb-3">
              <Form.Label>Manuals Requirements</Form.Label>
              <Form.Control
                placeholder="Manuals Requirements"
                name="manualsRequirements"
                onChange={handleChange}
                value={form.manualsRequirements}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="valid-group mb-3">
              <Form.Label>Valid from</Form.Label>
              <DateTimePicker
                className="form-control"
                name="validFrom"
                format={dateTimeFormat || "yyyy-MM-dd"}
                onChange={(val) => handleSelectChange("validFrom", val)}
                value={form.validFrom}
                calendarIcon={<CalendarMiniIcon />}
                clearIcon={<ClearIcon />}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="valid-group mb-3">
              <Form.Label>Valid to</Form.Label>
              <DateTimePicker
                className="form-control"
                name="validTo"
                format={dateTimeFormat || "yyyy-MM-dd"}
                onChange={(val) => handleSelectChange("validTo", val)}
                value={form.validTo}
                calendarIcon={<CalendarMiniIcon />}
                clearIcon={<ClearIcon />}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group className="valid-group mb-3">
              <Form.Label>Regions</Form.Label>
              <RegionSelector
                onSelected={(val) => handleSelectChange("regions", val)}
                value={form.regions.map((val: any) => val.value)}
                setRegionsData={setRegionsData}
                setIsRegionManualChanged={setIsRegionManualChanged}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="valid-group mb-3">
              <Form.Label>Countries</Form.Label>
              <CountrySelector
                onSelected={(val) => handleSelectChange("countries", val)}
                value={form.countries.map((val: any) => val.value)}
                regionIds={regionValues}
                regionsData={regionsData}
                isRegionManualChanged={isRegionManualChanged}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className="chip-container">
              <Chips
                value={form.requirements}
                onChange={(val) => handleSelectChange("requirements", val)}
                createChipKeys={[9, 13, 32, 4, 1]}
                uniqueChips={true}
                multiSection
                placeholder="Requirements"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="chip-container">
              <Chips
                value={form.sources}
                onChange={(val) => handleSelectChange("sources", val)}
                createChipKeys={[9, 13, 32, 4, 1]}
                uniqueChips={true}
                multiSection
                placeholder="Sources"
              />
            </div>
          </Col>
        </Row>

        <Row className="my-4">
          <div className="d-flex justify-content-center flex-wrap">
            {form.image ? (
              <img
                src={`${form.image}`}
                alt="Uploaded Thumbnail"
                style={{
                  width: "200px",
                  height: "auto",
                  padding: "12px",
                  borderRadius: "21px",
                  maxWidth: "100%",
                }}
              />
            ) : (
              <div
                style={{
                  width: "200px",
                  height: "auto",
                  padding: "12px",
                  borderRadius: "14px",
                  maxWidth: "100%",
                }}
                className="border border-2 border-dashed text-center mb-4"
              >
                Image here
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexFlow: "column",
                gap: "13px",
                justifyContent: "center",
                minWidth: "40%",
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="file-input"
              />

              <button
                className="btn btn-primary"
                onClick={() => document.getElementById("file-input")?.click()}
                style={{ width: "100%", maxWidth: "300px" }}
                type="button"
              >
                Choose Image
              </button>
              {form.image && (
                <button
                  className="btn btn-secondary mb-2"
                  onClick={removeFile}
                  type="button"
                  style={{ width: "100%", maxWidth: "300px" }}
                >
                  Remove Image
                </button>
              )}
            </div>
          </div>
        </Row>
        <Requirements
          requirements={form.adherenceRequirements}
          onRequirementChange={handleRequirementsChange}
        />
        <Button type="submit" className="w-100">
          {isLoading ? "Loading..." : "Create"}
        </Button>
      </Form>
    </div>
  );
};

export default MarkingForm;
