import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Product } from "../../models/Product";
import { Unwrap } from "../../utils/api/apiClient";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { dangerConfirmation } from "../Helper_component/Swal";
import { ProductTemplate } from "../../models/ProductTemplate";
import useRootStore from "../../hooks/rootStoreHook";
import { PagedList } from "../../models/PagedList";
import Chips from "react-chips";
import customSelect from "../../styles/custom-select";
import Dropzone from "../Tools/Dropzone/DropzoneImages";
import { ProductCategoryOption, ProductNewCategoryOption } from "../../models/ProductCategoryOption";

interface FormData {
  name: string;
  description: string;
}

export interface CreateProductVm extends FormData {
  tags: string[];
  productTemplateId: string | undefined;
}
interface PreviewFile extends File {
  preview: string;
}

export const CreateProduct: React.FC<{
  onProductAdded: (product: Product) => void;
  onProductAdding?: (product: CreateProductVm) => void;
}> = function (props) {
  const { apiClient } = useRootStore();
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm<FormData>();
  const [templates, setTemplates] = useState<ProductTemplate[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [templateId, setTemplateId] = useState<string | undefined>();
  const [uploadedFile, setuploadedFile] = useState<PreviewFile>();
  const [newsFilters, setNewsFilters] = useState<ProductCategoryOption[]>([]);
  const [productCategories, setProductCategories] = useState([]);


  useEffect(() => {
    //TODO: This should have a search functionality instead of listing everything
    apiClient.get("ProductTemplates?page=1&pageSize=100").then((res) => {
      let result = Unwrap.result<PagedList<ProductTemplate>>(res);
      setTemplates(result!.pageResults!);
    });
  }, [apiClient]);

  
  useEffect(() => {
    const getProductCategories = async () => {
      const response = await apiClient.get("/Products/GetProductCategories");
      const result = Unwrap.result(response);
      setProductCategories(result);
    }

    getProductCategories();
  }, []);


  async function addProduct(data: FormData, e: any) {
    let vm = {
      description: data.description,
      name: data.name,
      categories: selectedCategories.map((a) => a.value),
      tags: tags ?? [],
      productTemplateId: templateId,
    } as CreateProductVm;

    let res = await apiClient.post("products", vm);
    props.onProductAdded(Unwrap.result<Product>(res)!);

    const productId = res.data.result.id;

    if (uploadedFile) {
      const formData = new FormData();

      formData.append("file", uploadedFile[0]);

      let response = await apiClient.put(
        `products/${productId}/thumbnailImage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
  }

  const handleSelectedCategories = (category: any) => {
    setSelectedCategories(category);
  };

  const handleFilesChange = (files: any) => {
    setuploadedFile(files);
  };

  useEffect(() => {
    apiClient.get("news/filters").then((a) => {
      setNewsFilters(Unwrap.result(a).tags.ConsumerGood);
    });
  }, [apiClient]);

  async function productTemplateHandler(e: ProductTemplate) {
    setTemplateId(e.id);
    let fieldValues = getValues();
    let overwrite = true;
    let overwritingFields: string[] = [];
    if (tags.length > 0 && e.tags.length > 0) overwritingFields.push("tags");
    if (overwritingFields.length > 0) {
      let res = await dangerConfirmation.fire(
        "Overwrite data?",
        `The template will overwrite ${overwritingFields.join(" and ")}`
      );
      overwrite = res.value === true;
    }
    if (overwrite) {
      if (fieldValues.description === undefined)
        setValue("description", e.description);
      setTags(e.tags ?? []);
    }
  }

  return (
    <Form onSubmit={handleSubmit(addProduct)}>
      <div className="row">
        <div className="col-md-6">
          <Form.Group className="mb-2">
            <Form.Label>Product name *</Form.Label>
            <Form.Control
              ref={register({ required: true }) as any}
              name="name"
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              A names is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Tags</Form.Label>
            <Chips
              createChipKeys={[9, 13, 32]}
              uniqueChips={true}
              value={tags}
              onChange={setTags}
            />
            <Form.Control.Feedback type="invalid">
              Name is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>From template</Form.Label>
            <Select
              styles={customSelect("primary")}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              options={templates}
              placeholder="Use template..."
              onChange={(e: any) => {
                productTemplateHandler(e);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Categories</Form.Label>
            <Select
              styles={customSelect("primary")}
              isMulti
              getOptionLabel={(option) => option.label} // Make sure "tag" is the right property for label
              getOptionValue={(option) => option.value} // If "tag" is unique, you can use it as value
              options={productCategories.map((cat: ProductNewCategoryOption) => ({
                label: cat.name,
                value: cat.id,
              }))}
              placeholder="Choose categories..."
              onChange={handleSelectedCategories}
            />
          </Form.Group>
          <Form.Group>
            <Dropzone
              className="custom-dropzone"
              isMultiple={false}
              onFilesChange={handleFilesChange}
            />
          </Form.Group>
        </div>
        <div className="col-md-6 d-flex flex-column">
          <Form.Label>Description</Form.Label>
          <Controller
            as={
              <Form.Control
                as="textarea"
                className="flex-grow-1"
              ></Form.Control>
            }
            control={control}
            name="description"
          ></Controller>
        </div>
      </div>
      <div className="text-center mt-3">
        <Button type="submit" style={{width: 120}}>Add</Button>
      </div>
    </Form>
  );
};
